export const column = [
  { type: 'text', label: 'ID', value: 'id' },
  { type: 'text', label: 'appId', value: 'appId' },
  {
    type: 'boolean',
    label: '是否开启',
    value: 'open',
    options: ['否', '是']
  },
  { type: 'text', label: '原因', value: 'reason' },
  { type: 'text', label: '规则', value: 'ruleKey' },
  {
    type: 'hash',
    label: '规则类型',
    value: 'ruleType',
    options: {
      1: '用户',
      2: 'ip',
      3: '设备'
    }
  },
  {
    type: 'hash',
    label: '作用域',
    value: 'scopeType',
    options: {
      // 封禁、注册、充值
      1: '1-封禁',
      2: '2-注册',
      3: '3-充值'
    }
  },
  {
    type: 'boolean',
    label: '是否过期',
    value: 'expired',
    options: ['否', '是']
  },
  { type: 'text', label: '创建时间', value: 'createdAt' },
  { type: 'text', label: '更新时间', value: 'updatedAt' },
  { type: 'text', label: '过期时间', value: 'expiredTime' }
]
