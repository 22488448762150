<template>
  <el-dialog title='产品' :visible='dialogVisible' @close='handleCloseDialog' label-position='left' top='4vh' width="70%">
    <el-form :model='form' :rules='rules' ref='form' label-position='right' label-width="100px">
      <el-form-item label='ID' prop='id'>
        <el-input v-model.number='form.id' :disabled='type' style='width: 100%' ></el-input>
      </el-form-item>
      <el-form-item label='名称' prop='name'>
        <el-input v-model.trim='form.name' placeholder='请输入名称'></el-input>
      </el-form-item>
      <el-form-item label='AppKey' prop='appKey'>
        <el-input v-model.trim='form.appKey' placeholder='请输入appKey'></el-input>
      </el-form-item>
      <el-form-item label='AppSecret' prop='appSecret'>
        <el-input v-model.trim='form.appSecret' placeholder='请输入appSecret'></el-input>
      </el-form-item>
      <el-form-item label='通知地址' prop='notifyUrl'>
        <el-input v-model.trim='form.notifyUrl' placeholder='请输入NotifyUrl'></el-input>
      </el-form-item>
      <el-form-item label="广告位">
        <el-form :model='form' size='mini'>
          <el-form-item style="margin-top: 20px">
            <!--表头-->
            <el-form-item style='float: left;margin-bottom: 5px;width:100%'>
              <el-row :gutter='20' style='text-align: left;font-weight: bold'>
                <el-col :span='2'><span>广告位ID</span></el-col>
                <el-col :span='3'><span>广告位名称</span></el-col>
                <el-col :span='3'><span>奖励名称</span></el-col>
                <el-col :span='2'><span>奖励数量</span></el-col>
                <el-col :span='3'><span>穿山甲广告位ID</span></el-col>
                <el-col :span='3'><span>穿山甲Secret</span></el-col>
                <el-col :span='3'><span>腾讯广告位ID</span></el-col>
                <el-col :span='3'><span>优量汇Secret</span></el-col>
                <el-col :span='2'><span>操作</span></el-col>
              </el-row>
            </el-form-item>
            <!--参数-->
            <el-form-item v-for='(item, i) in form.poses' :key='i' style='float: left;margin-bottom: 5px;width:100%'>
              <el-row :gutter='10'>
                <el-col :span='2'><el-input v-model='item.posId' placeholder='广告位ID'> </el-input></el-col>
                <el-col :span='3'><el-input v-model='item.posName' placeholder='广告位名称'> </el-input></el-col>
                <el-col :span='3'><el-input v-model='item.rewardName' placeholder='奖励名称'> </el-input></el-col>
                <el-col :span='2'><el-input v-model='item.rewardAmount' placeholder='奖励数量'> </el-input></el-col>
                <el-col :span='3'><el-input v-model='item.pangolinPosId' placeholder='穿山甲广告位ID'> </el-input></el-col>
                <el-col :span='3'><el-input v-model='item.pangolinAppSecret' placeholder='穿山甲Secret'> </el-input></el-col>
                <el-col :span='3'><el-input v-model='item.tencentPosId' placeholder='腾讯广告位ID'> </el-input></el-col>
                <el-col :span='3'><el-input v-model='item.tencentSecret' placeholder='优量汇Secret'> </el-input></el-col>
                <el-col :span='2'><el-button type='info' size='mini' circle icon="el-icon-delete" @click='handleDeleteParams(i)'> </el-button></el-col>
              </el-row>
            </el-form-item>
            <el-form-item v-if="formatJson">
              <el-input type="textarea" placeholder="请输入内容" v-model="textarea"> </el-input>
            </el-form-item>
            <el-form-item style='float: left'>
              <el-button type='primary' size='mini' @click='handleAddParams'>添加</el-button>
<!--              <el-button type='primary' size='mini' @click='handleFormatJson'>自动转换</el-button>-->
            </el-form-item>
          </el-form-item>
        </el-form>
      </el-form-item>
    </el-form>
    <div slot='footer' class='dialog-footer' style='text-align: left'>
      <el-button type='primary' @click='handleSaveDialog' >保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api/channel'

export default {
  name: 'ProductDialog',
  props: {
    dialogVisible: Boolean,
    posesData: Object
  },
  created () {
    // console.log(this.posesData)
  },
  watch: {
    posesData: function (val) {
      this.type = !!val.name // true 为改变 false 为新增
      this.form = Object.assign({}, val)
    }
  },
  data () {
    return {
      form: {
        poses: [{ posId: '', posName: '', rewardName: '', rewardAmount: '', pangolinPosId: '', pangolinAppSecret: '', tencentPosId: '', tencentSecret: '' }]
      },
      type: '',
      textarea: '',
      formatJson: false,
      rules: {
        id: [{ type: 'number', required: true, message: '请输入数字，且不能为空', trigger: 'change' }],
        name: [{ type: 'string', required: true, message: '请输入应用名称', trigger: 'blur' }],
        appKey: [{ type: 'string', required: true, message: 'appKey不能为空', trigger: 'blur' }],
        appSecret: [{ type: 'string', required: true, message: 'appSecret不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 关闭模态框
    handleCloseDialog () {
      this.$refs.form.clearValidate()
      this.$emit('handleCloseDialog', false)
    },
    // 发送数据判断是 添加 还是 修改  成功之后关闭
    handleSaveDialog () {
      // 先进行表单验证
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.form)
          if (params.poses) {
            let len = params.poses.length
            const arr = params.poses
            while (len--) {
              if (arr[len].posId === '') {
                arr.splice(len, 1)
              }
            }
          }
          params.poses = params.poses && params.poses.length > 0 ? JSON.stringify(params.poses) : null
          const f = !this.type ? api.postAdnetGames : api.putAdnetGames
          f(params).then(() => {
            this.$message.success(this.type ? '修改成功' : '添加成功')
            this.$emit('handleSaveDialog', false)
          })
        } else {
          return false
        }
      })
    },
    // 添加参数
    handleAddParams () {
      const params = { posId: '', posName: '', rewardName: '', rewardAmount: '', pangolinPosId: '', pangolinAppSecret: '', tencentPosId: '', tencentSecret: '' }
      if (!this.form.poses) {
        this.form.poses = []
      }
      this.form.poses.push(params)
    },
    // 删除参数
    handleDeleteParams (i) {
      this.form.poses.splice(i, 1)
    },
    handleClose (done) {
      done()
    }
    // 格式转换
    // handleFormatJson () {
    //   if (this.formatJson) {
    //     if (!this.textarea) {
    //       return this.$message.info('请输入内容')
    //     }
    //     try {
    //       let data = JSON.parse(this.textarea)
    //       console.log(data)
    //     } catch (e) {
    //       return this.$message.info('请输入正确格式')
    //     }
    //   } else {
    //     this.formatJson = true
    //   }
    // }
  }
}
</script>

<style scoped>

</style>
