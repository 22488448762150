<template>
  <el-dialog title='产品' :visible='dialogVisible' @close='handleCloseCurrencyDialog' label-position='left' top='4vh'>
    <el-form size='mini'>
    <el-form-item label='货币类型' prop='options'>
      <el-select v-model='currency' placeholder='请选择货币类型' style='width: 100%' @change="handleSelectChange">
        <el-option v-for='item in options' :key='item.value' :label='item.value + "-" + item.label' :value='item.value'></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="商品档位">
      <el-form size='mini'>
        <el-form-item style="margin-top: 20px">
          <!--表头-->
          <el-form-item style='float: left;margin-bottom: 5px;width:100%'>
            <el-row :gutter='20' style='text-align: left;font-weight: bold'>
              <el-col :span='5'><span>商品ID</span></el-col>
              <el-col :span='5'><span>价格(分)</span></el-col>
              <el-col :span='5'><span>商品名称</span></el-col>
              <el-col :span='6'><span>商品描述</span></el-col>
              <el-col :span='3'><span>操作</span></el-col>
            </el-row>
          </el-form-item>
          <!--参数-->
          <el-form-item v-for='(item, i) in products' :key='i' style='float: left;margin-bottom: 5px;width:100%'>
            <el-row :gutter='10'>
              <el-col :span='5'><el-input v-model='item.id' placeholder='商品ID'> </el-input></el-col>
              <el-col :span='5'><el-input v-model='item.price' placeholder='价格(分)'> </el-input></el-col>
              <el-col :span='5'><el-input v-model='item.name' placeholder='商品名称'> </el-input></el-col>
              <el-col :span='6'><el-input v-model='item.desc' placeholder='商品描述'> </el-input></el-col>
              <el-col :span='3'><el-button type='info' size='mini' circle icon="el-icon-delete" @click='handleDeleteParams(i)'> </el-button></el-col>
            </el-row>
          </el-form-item>
          <el-form-item v-if="formatJson">
            <el-input type="textarea" placeholder="请输入内容" v-model="textarea"> </el-input>
            <el-button type='primary' size='mini' @click='handleFormatJson'>自动转换</el-button>
          </el-form-item>
          <el-form-item style='float: left'>
            <el-button type='primary' size='mini' @click='handleAddParams'>添加单行</el-button>
            <el-button type='primary' size='mini' @click='handleAddTextarea'>粘贴文本</el-button>
          </el-form-item>
        </el-form-item>
      </el-form>
    </el-form-item>
  </el-form>
    <div slot='footer' class='dialog-footer' style='text-align: left'>
      <el-button type='primary' @click='handleSaveDialog'>保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import options from '@/common/options'
import api from '@/api/channel'

export default {
  name: 'currencyDialog',
  props: {
    dialogVisible: Boolean,
    productData: Object
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    }
  },
  watch: {
    productData: function (val) {
      this.products = Object.assign([], val.products)
      this.currency = val.currency
    }
  },
  data () {
    return {
      options: options.currency,
      currency: '',
      products: [{ id: '', name: '', price: 0, desc: '' }],
      textarea: '',
      formatJson: false
    }
  },
  methods: {
    // 添加参数
    handleAddParams () {
      const params = { id: '', name: '', price: 0, desc: '' }
      if (!this.products) {
        this.products = []
      }
      this.products.push(params)
    },
    // 删除参数
    handleDeleteParams (i) {
      this.products.splice(i, 1)
    },
    // 切换货币类型
    handleSelectChange () {
      const params = { appId: this.productData.id, currency: this.currency }
      console.log(params)
      // 请求货币接口
      api.getCurrencyData(params).then(res => {
        const data = Object.assign({}, res)
        if (data.products) {
          this.products = JSON.parse(data.products)
        }
      })
    },
    // 保存
    handleSaveDialog () {
      // 将空的参数删掉
      const params = {}
      if (this.products) {
        let len = this.products.length
        const arr = [...this.products]
        while (len--) {
          if (arr[len].id === '') {
            arr.splice(len, 1)
          }
        }
        params.products = arr
      }
      params.products = params.products && params.products.length > 0 ? JSON.stringify(params.products) : null
      params.currency = this.currency
      params.appId = this.productData.id
      // 保存金额列表
      api.addOrChangeCurrency(params).then(res => {
        this.$message.success('保存成功')
        this.$emit('handleSaveCurrencyDialog', false)
      })
    },
    // 关闭模态框
    handleCloseCurrencyDialog () {
      this.$emit('handleCloseCurrencyDialog', false)
    },
    // 打开文本输入
    handleAddTextarea () {
      this.formatJson = !this.formatJson
    },
    // 格式转换
    handleFormatJson () {
      if (!this.textarea) {
        return this.$message.info('请输入内容')
      }
      if (typeof this.textarea !== 'string') {
        return this.$message.info('请输入字符串类型')
      }
      if (this.isJsonString(this.textarea)) {
        this.products = JSON.parse(this.textarea)
      } else {
        return this.$message.info('请输入正确格式')
      }
    },
    isJsonString (str) {
      try {
        if (typeof JSON.parse(str) === 'object') {
          return true
        }
      } catch (e) {
      }
      return false
    }
  }

}
</script>

<style scoped>

</style>
