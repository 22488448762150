<template>
  <section>
    <div class="main-header">
<!--      <h3 style='text-align: left;margin: 0'>节假日管理</h3>-->
      <div style="display: flex;justify-content: space-between;">
        <el-button type='primary' @click='handleAddHoliday'>新增</el-button>
        <el-input v-model="search" placeholder="输入关键字搜索" style="width: 180px"></el-input>
      </div>
    </div>
    <el-card>
      <template>
        <el-table :data='tables' stripe style='width: 100%' :header-cell-style='setTableHeader'>
          <el-table-column prop='id' label='ID' width='80px'> </el-table-column>
          <el-table-column prop='year' label='年份' width='100px'>
            <template slot-scope='scope'>
              <el-button type='number' @click='handleUpdateHoliday(scope.$index, scope.row)'>{{scope.row.year}}</el-button>
            </template>
          </el-table-column>

          <el-table-column prop='festivals' label='节日列表'>
            <template slot-scope='scope'>
              <el-button type='text' size='small' @click='handleUpdateFestival(scope.$index, scope.row)'>{{festivalsToStr(scope.row.holiday)}}</el-button>
            </template>
          </el-table-column>

          <el-table-column prop='workings' label='工作日列表'>
            <template slot-scope='scope'>
              <el-button type='text' size='small' @click='handleUpdateWorking(scope.$index, scope.row)'>{{workingsToStr(scope.row.working)}}</el-button>
            </template>
          </el-table-column>

          <el-table-column label='操作' fixed='right' align='right' width="60">
            <template slot-scope='scope'>
              <el-button type='info' size='mini' circle @click='handleDeleteHoliday(scope.row.id, scope.$index)' icon='el-icon-delete'></el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-card>
<!--    dialog-->
    <holiday-dialog :holidayData="holidayData" :dialogVisible="holidayDialogVisible" @handleSaveHolidayDialog="handleHolidayDialogChange" @handleCloseHolidayDialog="holidayDialogVisible=false"></holiday-dialog>
    <festival-dialog :holidayData="holidayData" :dialogVisible="festivalDialogVisible" @handleSaveFestivalDialog="handleFestivalDialogChange" @handleCloseFestivalDialog="festivalDialogVisible=false"></festival-dialog>
    <working-dialog :holidayData="holidayData" :dialogVisible="workingDialogVisible" @handleSaveWorkingDialog="handleWorkingDialogChange" @handleCloseWorkingDialog="workingDialogVisible=false"></working-dialog>
  </section>
</template>

<script>
import FestivalDialog from './FestivalDialog'
import WorkingDialog from './WorkingDialog'
import HolidayDialog from './HolidayDialog'
import api from '@/api/channel'
import f from '@/filter'

export default {
  name: 'index',
  components: { FestivalDialog, WorkingDialog, HolidayDialog },
  created () {
    this.fetchData()
  },
  data () {
    return {
      tableData: [],
      search: '',
      festivalDialogVisible: false,
      workingDialogVisible: false,
      holidayDialogVisible: false,
      holidayData: {}
    }
  },
  computed: {
    id () {
      return this.$store.getters.id
    },
    tables () {
      const search = this.search
      if (search) {
        return this.tableData.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(search) > -1
          })
        })
      }
      return this.tableData
    }
  },
  watch: {
    id (val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      api.getHolidays().then(res => {
        this.tableData = res
      })
    },
    // 修改产品
    handleUpdateHoliday (index, rowData) {
      this.holidayData = Object.assign({}, rowData)
      this.holidayDialogVisible = true
    },
    // 修改假日
    handleUpdateFestival (index, rowData) {
      // 将json字符串转成json对象，发送到节日dialog
      const data = Object.assign({}, rowData)
      this.holidayData = data
      console.log(this.holidayData)
      // 打开节日dialog
      this.festivalDialogVisible = true
    },
    // 修改补班日
    handleUpdateWorking (index, rowData) {
      // 将json字符串转成json对象，发送到货币dialog
      const data = Object.assign({}, rowData)
      this.holidayData = data
      console.log(this.holidayData)
      // 打开补班日dialog
      this.workingDialogVisible = true
    },
    // 新增节假日
    handleAddHoliday () {
      const id = this.tableData.length ? this.tableData[this.tableData.length - 1].id : 0
      this.holidayData = {
        id: id + 1
      }
      this.holidayDialogVisible = true
    },
    // 产品Dialog
    handleHolidayDialogChange (value) {
      // value可能接收的是一个布尔值，也有可能接收的是一个对象
      if (typeof value === 'object') {
        this.holidayDialogVisible = false
        this.fetchData()
      } else {
        this.holidayDialogVisible = value
      }
    },

    // 节假日Dialog
    handleFestivalDialogChange (value) {
      this.festivalDialogVisible = value
      this.fetchData()
    },

    // 补班日Dialog
    handleWorkingDialogChange (value) {
      this.workingDialogVisible = value
      this.fetchData()
    },

    // 转换节日展示
    festivalsToStr (holiday) {
      return holiday ? f.festivalsToStr(holiday) : []
    },
    // 转换补班日展示
    workingsToStr (working) {
      return working ? f.workingsToStr(working) : []
    },
    // 删除产品
    handleDeleteHoliday (id, i) {
      this.$confirm('确定删除吗?', '提示', { type: 'warning' }).then(() => {
        api.deleteHoliday(id).then(() => {
          this.tableData.splice(i, 1)
          this.$message.success('删除成功')
        })
      })
    },
    setTableHeader () {
      return 'color: #000'
    }

  }
}
</script>

<style scoped>

</style>
