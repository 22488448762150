<template>
  <div class="file-upload">
    <div class="upload" v-show="fileList && (fileList.length < uploadSize.max)">
      <template>
        <template v-if="$slots.default">
          <slot></slot>
        </template>
        <template v-else>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </template>
      </template>
      <input
        class="file-input"
        ref="file"
        type="file"
        :accept="input.accept"
        :disabled="input.disabled"
        :multiple="input.multiple"
        @change="onChange"
      />
    </div>
    <div class="upload-file-list" v-show="fileList && fileList.length">
      <template v-for="(item, index) in fileList">
        <el-tag :key="index" closable @close="onClose(index)">
          {{ item.name }}
        </el-tag>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileUpload',
  props: {
    column: {
      type: Object,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => {}
    },
    input: {
      type: Object,
      default: () => ({
        accept: 'image/*', // 上传类型
        disabled: false,
        multiple: false // 是否多选
      })
    },
    acceptRegExp: { // 上传文件类型正则匹配
      type: [RegExp, String],
      default: '.*'
    },
    uploadSize: { // 上传文件 min max 限制
      type: Object,
      default: () => ({
        min: 1,
        max: 1
      })
    },
    hideFile: {
      type: Boolean,
      default: false
    },
    // TODO: doing ~ 为true 直接调用 this.$emit('uploadUrl')
    uploadUrl: {
      type: [Boolean, Function],
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    fileList () {
      return this.hideFile ? [] : (this.formData ?? {})[(this.column ?? {}).value] ?? []
    }
  },
  watch: {},
  methods: {
    async onChange (e) {
      const { files } = e.target
      // console.log(files)
      // 过滤上传类型
      const fileList = await [...(files ?? [])].reduce(async (pre, cur) => {
        const suffix = cur.name.split('.').pop()
        if ((new RegExp(this.acceptRegExp)).test(`.${suffix}`)) {
          const file = this.uploadUrl ? await this.uploadUrl(cur, this.column, this.formData) : {
            name: cur.name,
            file: cur
          }
          // console.log(this.fileList)
          return pre.length ? [...pre, file] : [file]
        }
        return pre
      }, [])

      const form = this.formData
      this.$emit('update:formData', Object.assign(form, {
        [this.column.value]: this.uploadSize.max === 1 ? fileList[0] : fileList
      }))
      this.$refs.file.value = ''
      console.log(fileList, this.formData)
    },
    onClose (index) {
      // this.fileList.splice(index, 1)
      const list = [...this.fileList].splice(index + 1, 1)

      this.$emit('update:formData', Object.assign(this.formData, {
        [this.column.value]: list
      }))
    }
  }
}
</script>

<style scoped>
.file-upload {
  position: relative;
}
.file-upload .upload {
  position: relative;
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  /* width: 360px; */
  max-width: 360px;
  height: 180px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}
.file-upload .file-input {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.file-upload .el-icon-upload {
  font-size: 67px;
  color: #C0C4CC;
  margin: 40px 0 16px;
  line-height: 50px;
}
.file-upload .el-upload__text em {
  color: #409EFF;
  font-style: normal;
}
.file-upload .el-upload__text {
  color: #606266;
  font-size: 14px;
  text-align: center;
}
</style>
