<template>
  <section>
    <h2>欢迎来到渠道管理系统</h2>
  </section>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped>
h2 {
  width: 100%;
  text-align: center;
}
</style>
