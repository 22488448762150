import { render, staticRenderFns } from "./ProductDialog.vue?vue&type=template&id=468ce6dc&scoped=true&"
import script from "./ProductDialog.vue?vue&type=script&lang=js&"
export * from "./ProductDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468ce6dc",
  null
  
)

export default component.exports