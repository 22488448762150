<template>
  <section>
    <div class="main-header">
<!--      <h3 style='text-align: left;margin: 0'>产品管理</h3>-->
      <div style="display: flex;justify-content: space-between;">
        <el-button type="primary" @click="handleAddProduct">新增</el-button>
        <el-input v-model="search" placeholder="输入关键字搜索" style="width: 180px"></el-input>
      </div>
    </div>
    <el-card>
      <template>
        <el-table :data="tables" stripe style="width: 100%" :header-cell-style="setTableHeader">
          <el-table-column prop="id" label="ID" width="60"> </el-table-column>
          <el-table-column prop="name" label="名称" width="110">
            <template slot-scope="scope">
              <el-button type="text" @click="handleUpdateProduct(scope.$index, scope.row)">{{scope.row.name}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="ufoGroup" label="ufo分组" width="80"></el-table-column>
          <el-table-column prop="appKey" label="AppKey" width="100">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="click" :content="scope.row.appKey">
                <el-button slot="reference" size="mini">查看</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="appSecret" label="AppSecret" width="100">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="click" :content="scope.row.appSecret">
                <el-button slot="reference" size="mini">查看</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="notifyUrl" label="CP-NotifyUrl" width="110">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="click" :content="scope.row.notifyUrl">
                <el-button slot="reference" size="mini">查看</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="h5LoginUrl" label="CP-H5Url" width="100">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="click" :content="scope.row.h5LoginUrl">
                <el-button slot="reference" size="mini" v-if="scope.row.h5LoginUrl">查看</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="gameUrl" label="CP-微端Url" width="100">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="click" :content="scope.row.gameUrl">
                <el-button slot="reference" size="mini" v-if="scope.row.gameUrl">查看</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <!--          <el-table-column prop="gatewayUrl" label="GatewayUrl" width="100">-->
          <!--            <template slot-scope="scope">-->
          <!--              <el-popover placement="bottom" trigger="click" :content="scope.row.gatewayUrl">-->
          <!--                <el-button slot="reference" size="mini" v-if="scope.row.gatewayUrl">查看</el-button>-->
          <!--              </el-popover>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column prop="products" label="金额列表">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleUpdateCurrency(scope.$index, scope.row)">{{productsToStr(scope.row.products)}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="minAge" label="适龄" width="80"></el-table-column>
          <el-table-column prop="bizId" label="bizId" width="80">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="click" :content="scope.row.bizId">
                <el-button slot="reference" size="mini" v-if="scope.row.bizId">查看</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="realAuthOpen" label="实名" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.realAuthOpen" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="juvenileOpen" label="防沉迷" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.juvenileOpen" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" :formatter="productStatusFormatter" width="100"></el-table-column>
          <el-table-column prop="plutoOpen" label="Pluto" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.plutoOpen" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="ufwOpen" label="UFW" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.ufwOpen" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="phoneBindOpen" label="手机号绑定" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.phoneBindOpen" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" align="right" width="60">
            <template slot-scope="scope">
              <el-button type="info" size="mini" circle @click="handleDeleteProduct(scope.row.id, scope.$index)" icon="el-icon-delete"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-card>
<!--    dialog-->
    <product-dialog :productData="productData" :dialogVisible="productDialogVisible" @handleSaveProductDialog="handleProductDialogChange" @handleCloseProductDialog="productDialogVisible=false"></product-dialog>
    <currency-dialog :productData="productData" :dialogVisible="currencyDialogVisible" @handleSaveCurrencyDialog="handleCurrencyDialogChange" @handleCloseCurrencyDialog="currencyDialogVisible=false"></currency-dialog>
  </section>
</template>

<script>
import ProductDialog from './ProductDialog'
import CurrencyDialog from './CurrencyDialog'
import options from '@/common/options'
import api from '@/api/channel'
import f from '@/filter'

export default {
  name: 'index',
  components: { ProductDialog, CurrencyDialog },
  created () {
    this.fetchData()
  },
  data () {
    return {
      productStatusOption: options.productStatusOption,
      tableData: [],
      search: '',
      productDialogVisible: false,
      currencyDialogVisible: false,
      productData: {}
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    tables () {
      const search = this.search
      if (search) {
        return this.tableData.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(search) > -1
          })
        })
      }
      return this.tableData
    }
  },
  watch: {
    appId (val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      api.getAppData().then(res => {
        this.tableData = res
        this.$store.dispatch('ChangeApp', this.appId).then(() => {})
      })
    },
    // 修改产品
    handleUpdateProduct (index, rowData) {
      // data.products = JSON.parse(data.products)
      this.productData = Object.assign({}, rowData)
      this.productDialogVisible = true
    },
    // 修改货币
    handleUpdateCurrency (index, rowData) {
      // 将json字符串转成json对象，发送到货币dialog
      const data = Object.assign({}, rowData)
      data.products = JSON.parse(data.products)
      this.productData = data
      // 打开货币dialog
      this.currencyDialogVisible = true
    },
    // 新增产品
    handleAddProduct () {
      const id = this.tableData.length ? this.tableData[this.tableData.length - 1].id : 0
      this.productData = {
        id: id + 1,
        products: [{ id: '', name: '', price: 0, desc: '' }],
        status: 1,
        currency: 'CNY',
        gatewayUrl: 'https://sgw.begindcc.com',
        appKey: this.createRandomStr(26),
        appSecret: this.createRandomStr(32),
        realAuthOpen: false,
        phoneBindOpen: false,
        juvenileOpen: false,
        minAge: 8,
        bizId: '',
        areaId: '',
        ufoGroup: ''
      }
      this.productDialogVisible = true
    },
    // 产品Dialog
    handleProductDialogChange (value) {
      // value可能接收的是一个布尔值，也有可能接收的是一个对象
      if (typeof value === 'object') {
        this.productDialogVisible = false
        this.fetchData()
      } else {
        this.productDialogVisible = value
      }
    },
    // 货币Dialog
    handleCurrencyDialogChange (value) {
      this.currencyDialogVisible = value
      this.fetchData()
    },
    // 转换金额展示
    productsToStr (products) {
      return products ? f.productsToStr(products) : []
    },
    // 删除产品
    handleDeleteProduct (id, i) {
      this.$confirm('确定删除吗?', '提示', { type: 'warning' }).then(() => {
        api.deleteProduct(id).then(() => {
          this.tableData.splice(i, 1)
          this.$store.dispatch('ChangeAppList').then(() => {})
          this.$message.success('删除成功')
        })
      })
    },
    setTableHeader () {
      return 'color: #000'
    },
    // 随机生成数字字母(不区分大小写)
    createRandomStr (num) {
      let str = ''
      const range = num
      const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      for (let i = 0; i < range; i++) {
        const pos = Math.round(Math.random() * (arr.length - 1))
        str += arr[pos]
      }
      return str
    },
    productStatusFormatter (row, column, cellValue) {
      return f.productStatusFormat(cellValue)
    }
  }
}
</script>

<style scoped>

</style>
