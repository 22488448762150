import pluralize from 'pluralize'
import moment from 'moment'

export default {

  dateFormatter (date) {
    return date.toLocaleString('zh-cn', { hour12: false })
  },

  tableFormatter (item, value, row) {
    if (item.type === 'select') {
      if (item.options) {
        let values = []
        if (typeof value !== 'undefined' && value !== null) {
          values = item.multiple ? value.split(',') : [value]
        }
        return values.map((x) => {
          const option = item.options.filter((y) => y.value === x || y.value === String(x) || y.value === Number(x))[0]
          // eslint-disable-next-line no-nested-ternary
          return option ? (option.label.indexOf(' - ') < 0 ? `${option.value} - ${option.label}` : option.label) : x
        }).join('<br>')
      }
      console.warn(`select 没有 options，prop=${item.prop}`)
    }

    // if (item.prop.endsWith('Date') || item.prop.endsWith('Time') || item.prop === 'createdAt' || item.prop === 'updatedAt') {
    if (item.type === 'date') {
      if (!value) {
        return ''
      }
      const val = moment(value).format('YYYY-MM-DD')
      return this.dateFormatter(val)
    }

    if (item.type === 'datetime') {
      if (!value) {
        return ''
      }
      const val = moment(value).format('YYYY-MM-DD HH:mm:ss')
      return this.dateFormatter(val)
    }

    if (item.prop === 'id') {
      if (row._links) {
        return row._links.self.href.split('/').pop()
      }
    }

    if (typeof value === 'object') {
      if (value instanceof Array) {
        return `共${value.length}个`
      }
    }

    if (typeof value === 'string') {
      if (value.startsWith('res/')) {
        if (value.endsWith('.png') || value.endsWith('.jpg')) {
          return `<img style="height: 50px; width: auto;" src="${this.getCOSPrefix()}${value}" alt=""/>`
        }
        if (value.endsWith('.mp3')) {
          return `<audio controls><source src="${this.getCOSPrefix()}${value}" type="audio/mpeg"></audio>`
        }
      } else if (value.startsWith('#') && value.length === 7) {
        return `<span style="color: ${value}">█</span> ${value}`
      }
      const textLen = item.textLen ? item.textLen : 33
      if (value.length > textLen) {
        return `${value.substring(0, textLen - 3)}...`
      }
    }

    if (typeof value === 'boolean') {
      return value ? '<input type="checkbox" checked="checked" disabled>' : '<input type="checkbox" disabled>'
    }

    return value
  },

  firstLowerCase ([first, ...rest]) {
    return first.toLowerCase() + rest.join('')
  },

  pluralize (word) {
    return pluralize(word)
  },

  toHump (name) {
    return name.replace(/_(\w)/g, (all, letter) => letter.toUpperCase())
  },

  // https://stackoverflow.com/a/494348
  createElementFromHTML (htmlString) {
    const div = document.createElement('div')
    div.innerHTML = htmlString.trim()

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild
  },

  checkJSON (rule, value, callback) {
    setTimeout(() => {
      try {
        JSON.parse(value)
        return callback()
      } catch (e) {
        return callback(e)
      }
    }, 1000)
  },
  getDateRangeValue: days => {
    const startTime = moment().add(days, 'days').format('YYYY-MM-DD')
    const endTime = moment().add(days === -1 ? -1 : 0, 'days').format('YYYY-MM-DD')
    return [startTime, endTime]
  },
  // 防抖
  debounce: function (func, delay = 300) {
    let timeout
    return function () {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        func.apply(this, arguments)
      }, delay)
    }
  }
}
