<template>
  <section>
    <div class="main-header">
      <!--      <h3 style='text-align: left;margin: 0'>平台管理</h3>-->
      <div style="display: flex;justify-content: space-between;">
        <el-button type='primary' @click='handleAddPlatform'>新增</el-button>
        <!--        <el-input v-model="search" placeholder="请输入关键字搜索(页面)" style="width: 180px"></el-input>-->
        <el-input v-model="pfName" @input="pfNameChange" placeholder="输入关键字搜索" style="width: 180px"></el-input>
      </div>
    </div>
    <!--    主体内容-->
    <el-card>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount">
      </el-pagination>
      <el-table :data='tables' style='width: 100%' stripe :header-cell-style='setTableHeader'>
        <el-table-column prop='platformId' label='平台ID'></el-table-column>
        <el-table-column prop='name' label='平台名称'>
          <template slot-scope='scope'>
            <el-button type='text' @click='handleUpdatePlatform(scope.$index, scope.row)'>{{ scope.row.name }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop='platformType' label='平台类型' :formatter="platformTypeFormatter"></el-table-column>
        <el-table-column prop='min' label='最小渠道号'></el-table-column>
        <el-table-column prop='max' label='最大渠道号'></el-table-column>
        <el-table-column label='操作' align='right'>
          <template slot-scope='scope'>
            <el-button type='info' size='mini' @click='handleDeletePlatform(scope.row.id, scope.$index)'
                       icon='el-icon-delete' circle></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount">
      </el-pagination>
    </el-card>
    <!--逻辑模态框-->
    <el-dialog title='平台管理' :visible='dialogVisible' @close='handleCloseDialog' top='4vh'>
      <el-form :model='form' label-position='right' label-width="120px" :rules='rules' ref='form'>
        <el-form-item label='平台ID' prop='platformId'>
          <el-input v-model.number='form.platformId' :disabled='type' style='width: 100%'></el-input>
        </el-form-item>
        <el-form-item label='平台类型' prop='platformType'>
          <el-select v-model.trim='form.platformType' filterable placeholder='请选择' style='width: 100%' >
            <el-option value="0">0 - Unknown</el-option>
            <el-option v-for='item in platformTypes' :key='item.value' :label='item.value + " - " + item.label' :value='item.value'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='平台名称' prop='name'>
          <el-input v-model.trim='form.name'></el-input>
        </el-form-item>
        <el-form-item label='最小渠道号' prop='min'>
          <el-input v-model.number='form.min' style='width: 100%'></el-input>
        </el-form-item>
        <el-form-item label='最大渠道号' prop='max'>
          <el-input v-model.number='form.max' style='width: 100%'></el-input>
        </el-form-item>
      </el-form>
      <div slot='footer' class='dialog-footer' style='text-align: left'>
        <el-button type='primary' @click='handleSaveDialog'>保 存</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import api from '@/api/channel'
import options from '@/common/options'

export default {
  name: 'index',
  created () {
    this.fetchData(this.pagesize, this.currentPage)
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    tables () {
      const search = this.search
      if (search) {
        return this.tableData.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(search) > -1
          })
        })
      }
      return this.tableData
    }
  },
  watch: {
    appId (val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.fetchData(this.pagesize, this.currentPage)
    }
  },
  data () {
    const min = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入最小渠道号'))
      } else if (typeof (value) !== 'number') {
        callback(new Error('请输入数字'))
      } else if (value < 0) {
        callback(new Error('请输入有效数字'))
      } else if (value < this.appId * 1000) {
        callback(new Error('最小渠道号应大于等于' + this.appId * 1000))
      } else if (value >= (this.appId + 1) * 1000) {
        callback(new Error('最小渠道号应小于等于' + (this.appId + 1) * 1000))
      } else {
        callback()
      }
    }
    const max = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入最大渠道号'))
      } else if (typeof (value) !== 'number') {
        callback(new Error('请输入数字'))
      } else if (value < 0) {
        callback(new Error('请输入有效数字'))
      } else if (value < this.form.min) {
        callback(new Error('请大于最小渠道号'))
      } else if (value >= (this.appId + 1) * 1000) {
        callback(new Error('最大渠道号应小于' + (this.appId + 1) * 1000))
      } else if (value <= this.appId * 1000) {
        callback(new Error('最大渠道号应大于' + this.appId * 1000))
      } else {
        callback()
      }
    }
    return {
      pagesize: 20,
      currentPage: 1,
      totalCount: 0,
      tableData: [],
      platformData: {},
      dialogVisible: false,
      pfName: '',
      search: '',
      platformTypes: options.platformTypes,
      form: {
        appId: this.appId,
        platformType: 1
      },
      AppOptions: [],
      type: false, // 默认状态为新增  true 为修改 false为新增
      index: 0, // 用于修改时保存位置,
      rules: {
        platformId: [{ type: 'number', required: true, message: '请输入数字，且不能为空', trigger: 'blur' }],
        min: [{ required: true, validator: min, trigger: 'blur' }],
        max: [{ required: true, validator: max, trigger: 'blur' }],
        name: [{ type: 'string', required: true, message: '请输入名称', trigger: 'blur' }]
      }
    }
  },
  methods: {
    fetchData (n1, n2) {
      const params = {
        size: n1,
        page: n2,
        appId: this.appId,
        name: this.pfName
      }
      api.getPlatformDataByPage(params).then(res => {
        this.tableData = res.data
        this.totalCount = res.total
      })
      api.getAppData().then(res => {
        this.AppOptions = res
      })
    },
    platformTypeFormatter (row) {
      const platformType = row.platformType
      const item = this.platformTypes.find(item => item.value === platformType)
      if (item) {
        return item.value + '-' + item.label
      }
      return platformType
    },
    handleSizeChange (val) {
      this.pagesize = val
      this.currentPage = 1
      this.fetchData(val, this.currentPage)
    },
    // 显示第几页
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData(this.pagesize, val)
    },
    pfNameChange (val) {
      this.pfName = val
      this.currentPage = 1
      this.fetchData(this.pagesize, this.currentPage)
    },
    // 新增平台
    handleAddPlatform () {
      this.type = false
      const platformId = this.tableData.length ? this.tableData[this.tableData.length - 1].platformId : 0
      this.form = {
        platformId: platformId + 1,
        appId: this.appId
      }
      this.dialogVisible = true
    },
    // 修改平台
    handleUpdatePlatform (index, rowData) {
      this.type = true
      this.index = index
      const data = Object.assign({}, rowData)
      this.form = data
      this.dialogVisible = true
    },
    // 提交数据 新增 或 修改
    handleSaveDialog () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.form)
          const f = this.type ? api.changePlatform : api.addPlatform
          f(params).then(res => {
            if (this.type) {
              this.tableData.splice(this.index, 1, res)
            } else {
              this.tableData.push(res)
            }
            this.$message.success(this.type ? '修改成功' : '添加成功')
            this.dialogVisible = false
          })
        } else {
          return false
        }
      })
    },
    // 删除
    handleDeletePlatform (id, i) {
      this.$confirm('确定删除吗?', '提示', { type: 'warning' }).then(() => {
        api.deletePlatform(id).then(() => {
          this.tableData.splice(i, 1)
          this.$message.success('删除成功')
        })
      })
    },
    // 关闭弹窗
    handleCloseDialog () {
      this.dialogVisible = false
      this.$refs.form.clearValidate()
    },
    setTableHeader () {
      return 'color: #000'
    }
  }
}
</script>

<style scoped>
  e_card {
    margin-bottom: 20px;
  }
</style>
