<template>
<section>
  <div class="main-header">
<!--    <h3 style='text-align: left;margin: 0'>组织管理</h3>-->
    <div style="display: flex;justify-content: space-between;">
      <el-button type='primary' @click='handleAddCompany'>新增</el-button>
    </div>
  </div>
  <el-card>
    <template>
      <el-table :data="organizeData"
                style="width: 100%"
                v-loading="organizeTableLoading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="name" label="组织名称(唯一)">
          <template slot-scope="scope">
            <el-button type="text" @click="handleCheckCompany(scope.row, scope.$index)">{{scope.row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="company" label="所属企业"></el-table-column>
        <el-table-column prop="biUrl" label="BI链接"></el-table-column>
        <el-table-column prop="games" label="拥有游戏" :formatter="formatGame">
          <template slot-scope="scope">
            <el-button type="info" plain size="mini" v-if="scope.row.ownAll">全部</el-button>
            <el-tooltip placement="top" v-else-if="scope.row.games.length">
              <div slot="content">
                <span v-for="item in scope.row.games" :key="item">{{formatGame(item)}}<br/></span>
              </div>
              <el-button type="info" plain size="mini">拥有游戏</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" show-tooltip-when-overflow show-overflow-tooltip></el-table-column>
        <el-table-column prop="createdDate" label="创建时间" :formatter="formatTime"></el-table-column>
        <el-table-column prop="lastModifiedDate" label="最后修改时间" :formatter="formatTime"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleDeleteCompany(scope.row.id, scope.$index)" class="el-icon-delete" type="danger" size="small" plain circle></el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </el-card>
  <el-dialog :title="organizeStatus? '新增': '修改'" :visible.sync="organizeDialogFormVisible" :close-on-click-modal="false">
    <el-form :model="organizeForm" :rules="rules" ref="ruleForm" >
      <el-form-item label="组织名称(唯一)" prop="name">
        <el-input v-model.trim="organizeForm.name"></el-input>
      </el-form-item>
      <el-form-item label="所属企业">
        <el-input v-model.trim="organizeForm.company"></el-input>
      </el-form-item>
      <el-form-item label="BI链接" prop="biUrl">
        <el-input v-model.trim="organizeForm.biUrl"></el-input>
      </el-form-item>
      <el-form-item label="拥有游戏">
        <el-checkbox v-model="organizeForm.ownAll">全部</el-checkbox>
        <el-select v-if="!organizeForm.ownAll"
                   v-model="organizeForm.games"
                   filterable clearable multiple
                   collapse-tags style="margin-left: 20px;"
                   placeholder="请选择游戏列表">
          <el-option v-for="item in gameList"
            :key="item.id"
            :label="item.id + '-' + item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model.trim="organizeForm.remarks"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="organizeDialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleClickSubmitBtn">确 定</el-button>
    </div>
  </el-dialog>
</section>
</template>

<script>
import api from '@/api/channel'
import filter from '@/filter'
export default {
  name: 'company',
  created () {
    this.fetchData()
  },
  computed: {
    gameList () {
      return this.$store.getters.appList
    }
  },
  data () {
    const validateName = (rule, value, callback) => {
      const isHasName = this.organizeData.some((item, i) => item.name === value && this.index !== i)
      if (value === '') {
        callback(new Error('请输入名称'))
      } else {
        if (isHasName) {
          callback(new Error('名称已经存在，请换一个'))
        } else {
          callback()
        }
      }
    }
    const validateUrl = (rule, value, callback) => {
      if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
        callback(new Error('请输入有效的URL'))
      } else {
        callback()
      }
    }
    const rules = {
      name: [{ validator: validateName, trigger: 'blur', required: true }],
      biUrl: [{ validator: validateUrl, trigger: 'blur' }]
    }
    return {
      rules,
      organizeData: [],
      index: '',
      organizeStatus: false, // false 修改 true 新增
      organizeTableLoading: false,
      organizeDialogFormVisible: false,
      organizeForm: {
        name: '',
        company: '',
        biUrl: '',
        games: [],
        remarks: '',
        ownAll: false
      }
    }
  },
  methods: {
    fetchData () {
      this.organizeTableLoading = true
      api.httpOrganizeData().then(response => {
        this.organizeData = [...response]
        this.organizeData.forEach(item => {
          item.games = JSON.parse(item.games)
        })
        this.organizeTableLoading = false
      })
    },
    // 添加组织
    handleAddCompany () {
      this.initForm()
      this.organizeDialogFormVisible = true
      this.organizeStatus = true
    },
    // 查看/修改 组织
    handleCheckCompany (row, index) {
      this.index = index
      this.organizeStatus = false
      this.organizeForm = { ...row }
      this.organizeDialogFormVisible = true
    },
    // 点击提交按钮
    handleClickSubmitBtn () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.handleSubmitForm()
        } else {
          return false
        }
      })
    },
    // 提交表单
    handleSubmitForm () {
      const data = {
        id: this.organizeForm.id,
        type: this.organizeStatus ? 'post' : 'put',
        params: { ...this.organizeForm }
      }
      data.params.games = JSON.stringify(data.params.games)
      api.httpOrganizeData(data).then(response => {
        const data = { ...response }
        data.games = JSON.parse(data.games)
        this.organizeStatus ? this.organizeData.push(data) : this.organizeData.splice(this.index, 1, data)
        this.$message.success(this.organizeStatus ? '新增成功' : '修改成功')
        this.index = ''
        this.organizeStatus = false
        this.organizeDialogFormVisible = false
      })
    },
    // 删除数据
    handleDeleteCompany (id, i) {
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          id,
          type: 'delete'
        }
        api.httpOrganizeData(data).then(response => {
          this.organizeData.splice(i, 1)
          this.$message.success('删除成功')
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    initForm () {
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields()
      }
      this.organizeForm = {
        name: '',
        company: '',
        biUrl: '',
        games: [],
        remarks: '',
        ownAll: false
      }
    },
    // 格式化游戏名称
    formatGame (gameId) {
      const app = this.gameList.find(item => item.id * 1 === gameId * 1)
      return typeof app === 'object' ? app.id + '-' + app.name : gameId
    },
    // 格式化时间
    formatTime (row, col, cellValue) {
      return filter.formatTime(cellValue)
    }
  }
}
</script>

<style scoped>

</style>
