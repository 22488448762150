<template>
  <section>
    <div class="main-header">
      <div style="display: flex;justify-content: space-between;">
        <el-button type="primary" @click="handleAddEnv">新增</el-button>
        <el-input v-model="search" placeholder="输入关键字搜索" style="width: 180px"></el-input>
      </div>
    </div>
    <!--    主体内容-->
    <el-card>
      <el-table :data="tables" style="width: 100%" stripe :header-cell-style="setTableHeader">
        <el-table-column prop="id" label="环境ID"> </el-table-column>
        <el-table-column prop="name" label="环境名称">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdateEnv(scope.$index, scope.row)">{{scope.row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="env" label="环境ENV"> </el-table-column>
        <el-table-column prop="gatewayUrl" label="GateWay地址"> </el-table-column>
        <el-table-column prop="callbackUrl" label="CallBack地址"> </el-table-column>
        <el-table-column label="操作" align="right">
          <template slot-scope="scope">
            <el-button type="info" size="mini" @click="handleDeleteEnv(scope.row.id, scope.$index)" icon="el-icon-delete" circle></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!--逻辑模态框-->
    <el-dialog title="网关域名管理" :visible="dialogVisible" @close="handleCloseDialog" top="15vh">
      <el-form :model="form" label-position="right" label-width="120px" :rules="rules" ref="form">
        <el-form-item label="环境ID" prop="id">
          <el-input v-model.number="form.id" :disabled="type" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="环境名称" prop="name">
          <el-input v-model.trim="form.name" placeholder="示例 简体-xiayixing"></el-input>
        </el-form-item>
        <el-form-item label="环境ENV" prop="env">
          <el-input v-model.trim="form.env" placeholder="示例 zhcn-xiayi"></el-input>
        </el-form-item>
        <el-form-item label="GateWay地址" prop="gatewayUrl">
           <el-input v-model.trim="form.gatewayUrl" placeholder="示例 https://gw.xiayixing.com"></el-input>
        </el-form-item>
        <el-form-item label="CallBack地址" prop="callbackUrl">
           <el-input v-model.trim="form.callbackUrl" placeholder="示例 https://sgw.xiayixing.com"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: left">
        <el-button type="primary" @click="handleSaveDialog">提 交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import api from '@/api/channel'

export default {
  name: 'index',
  created () {
    this.fetchData()
  },
  computed: {
    id () {
      return this.$store.getters.id
    },
    tables () {
      const search = this.search
      if (search) {
        return this.tableData.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(search) > -1
          })
        })
      }
      return this.tableData
    }
  },
  watch: {
    id (val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.fetchData()
    }
  },
  data () {
    return {
      tableData: [],
      envData: {},
      dialogVisible: false,
      search: '',
      form: {
        id: this.id
      },
      AppOptions: [],
      type: false, // 默认状态为新增  true 为修改 false为新增
      index: 0, // 用于修改时保存位置,
      rules: {
        id: [{ type: 'number', required: true, message: '请输入数字，且不能为空', trigger: 'blur' }],
        name: [{ type: 'string', required: true, message: '请输入环境名称', trigger: 'blur' }],
        env: [{ type: 'string', required: true, message: '请输入环境描述', trigger: 'blur' }],
        gatewayUrl: [{ type: 'url', required: true, message: 'URL格式不正确', trigger: 'blur' }],
        callbackUrl: [{ type: 'url', required: true, message: 'URL格式不正确', trigger: 'blur' }]
      }
    }
  },
  methods: {
    fetchData () {
      // 获取已配置环境
      api.getAreas().then(res => {
        this.tableData = res
      })
    },
    // 新增平台
    handleAddEnv () {
      this.type = false
      const id = this.tableData.length ? this.tableData[this.tableData.length - 1].id : 0
      this.form = {
        id: id + 1
      }
      this.dialogVisible = true
    },
    // 修改平台
    handleUpdateEnv (index, rowData) {
      this.type = true
      this.index = index
      this.form = Object.assign({}, rowData)
      this.dialogVisible = true
    },
    // 提交数据 新增 或 修改
    handleSaveDialog () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.form)
          const f = this.type ? api.updateArea : api.addArea
          f(params).then(res => {
            if (this.type) {
              this.tableData.splice(this.index, 1, res)
            } else {
              this.tableData.push(res)
            }
            this.$message.success(this.type ? '修改成功' : '添加成功')
            this.dialogVisible = false
          })
        } else {
          return false
        }
      })
    },
    // 删除
    handleDeleteEnv (id, i) {
      this.$confirm('确定删除吗?', '提示', { type: 'warning' }).then(() => {
        api.deleteArea(id).then(() => {
          this.tableData.splice(i, 1)
          this.$message.success('删除成功')
        })
      })
    },
    // 关闭弹窗
    handleCloseDialog () {
      this.dialogVisible = false
      this.$refs.form.clearValidate()
    },
    setTableHeader () {
      return 'color: #000'
    }
  }
}
</script>

<style scoped>
  e_card {
    margin-bottom: 20px;
  }
</style>
