<template>
  <el-dialog title='节假日' :visible='dialogVisible' @close='handleCloseHolidayDialog' label-position='left' top='4vh'>
    <el-form :model='form' :rules='rules' ref='form' label-position='right' label-width="100px">
      <el-form-item label='ID' prop='id'>
        <el-input v-model.number='form.id' :disabled='type' style='width: 100%'></el-input>
      </el-form-item>
      <el-form-item label='年份' prop='year'>
        <el-input v-model.number='form.year' placeholder='请输入年份'></el-input>
      </el-form-item>
    </el-form>
    <div slot='footer' class='dialog-footer' style='text-align: left'>
      <el-button type='primary' @click='handleSaveDialog'>保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api/channel'

export default {
  name: 'HolidayDialog',
  props: {
    dialogVisible: Boolean,
    holidayData: Object
  },
  created () {
    // console.log(this.holidayData)
  },
  watch: {
    holidayData: function (val) {
      this.type = !!val.year // true 为改变 false 为新增
      const data = Object.assign({}, val)
      this.form = data
    }
  },
  data () {
    return {
      form: {
        id: this.holidayData.id
      },
      type: '',
      rules: {
        id: [{
          type: 'number',
          required: true,
          message: '请输入数字，且不能为空',
          trigger: 'change'
        }],
        year: [{
          type: 'number',
          required: true,
          message: '请输入数字，且不能为空',
          trigger: 'change'
        }]
      }
    }
  },
  methods: {
    // 关闭模态框
    handleCloseHolidayDialog () {
      this.$refs.form.clearValidate()
      this.$emit('handleCloseHolidayDialog', false)
    },
    // 发送数据判断是 添加 还是 修改  成功之后关闭
    handleSaveDialog () {
      // 先进行表单验证
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.form)
          // holiday已经单独拆分出dialog，这边不需要关心
          const f = this.type ? api.updateHoliday : api.addHoliday
          f(params).then(res => {
            this.$message.success(this.type ? '修改成功' : '添加成功')
            this.$emit('handleSaveHolidayDialog', res)
            // this.$store.dispatch('GetApp').then(() => {})
          })
        } else {
          return false
        }
      })
    },
    handleClose (done) {
      done()
    }
  }
}
</script>

<style scoped>

</style>
