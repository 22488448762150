<template>
  <el-dialog title='节假日' :visible='dialogVisible' @close='handleCloseFestivalDialog' label-position='left' top='4vh'>
      <el-form size='mini'>
        <el-form-item style="margin-top: 20px">
          <!--表头-->
          <el-form-item style='float: left;margin-bottom: 5px;width:100%'>
            <el-row :gutter='20' style='text-align: left;font-weight: bold'>
              <el-col :span='15'><span>日期</span></el-col>
              <el-col :span='3'><span>操作</span></el-col>
            </el-row>
          </el-form-item>
          <!--参数-->
          <el-form-item v-for='(item, i) in festivals' :key='i' style='float: left;margin-bottom: 5px;width:100%'>
            <el-row :gutter='20'>
              <el-col :span='15'><el-input v-model='item.label' holder='日期'> </el-input></el-col>
              <el-col :span='3'><el-button type='info' size='mini' circle icon="el-icon-delete" @click='handleDeleteParams(item)'> </el-button></el-col>
            </el-row>
          </el-form-item>
          <el-form-item v-if="formatJson">
            <el-input type="textarea" placeholder="请输入内容" v-model="textarea"> </el-input>
            <el-button type='primary' size='mini' @click='handleFormatJson'>自动转换</el-button>
          </el-form-item>
          <el-form-item style='float: left'>
            <el-button type='primary' size='mini' @click='handleAddParams'>添加单行</el-button>
            <el-button type='primary' size='mini' @click='handleAddTextarea'>粘贴文本</el-button>
          </el-form-item>
        </el-form-item>
      </el-form>
    <div slot='footer' class='dialog-footer' style='text-align: left'>
      <el-button type='primary' @click='handleSaveDialog'>保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api/channel'

export default {
  name: 'festivalDialog',
  props: {
    dialogVisible: Boolean,
    holidayData: Object
  },
  computed: {
    id () {
      return this.$store.getters.id
    }
  },
  watch: {
    holidayData: function (val) {
      if (val.holiday != null) {
        const arr = JSON.parse(val.holiday)
        const objs = []
        const list = [...arr]
        list.forEach(function (item, index) {
          objs.push(
            { id: index + 1, label: item }
          )
        })
        this.festivals = Object.assign([], objs)
      } else {
        const objs = []
        this.festivals = Object.assign([], objs)
      }
    }
  },
  data () {
    return {
      festivals: [],
      textarea: '',
      formatJson: false
    }
  },
  methods: {
    // 添加参数
    handleAddParams () {
      const params = {}
      if (!this.festivals) {
        this.festivals = []
      }
      this.festivals.push(params)
    },
    // 删除参数
    handleDeleteParams (i) {
      this.festivals.splice(i, 1)
    },
    // 保存
    handleSaveDialog () {
      // 将空的参数删掉
      const params = {}
      if (this.festivals) {
        let len = this.festivals.length
        const arr = [...this.festivals]
        while (len--) {
          if (arr[len].id === '') {
            arr.splice(len, 1)
          }
        }
        params.holiday = arr
      }
      params.holiday = params.holiday && params.holiday.length > 0 ? JSON.stringify(params.holiday) : null
      const list = JSON.parse(params.holiday)
      const objs = []
      list.forEach(function (item, index) {
        objs.push(item.label.toString())
      })
      params.holiday = JSON.stringify(Array.from(objs))
      params.working = this.holidayData.working
      params.year = this.holidayData.year
      params.id = this.holidayData.id
      // 保存节假日列表
      api.updateHoliday(params).then(res => {
        this.$message.success('保存成功')
        this.$emit('handleSaveFestivalDialog', false)
      })
    },
    // 关闭模态框
    handleCloseFestivalDialog () {
      this.$emit('handleCloseFestivalDialog', false)
    },
    // 打开文本输入
    handleAddTextarea () {
      this.formatJson = !this.formatJson
    },
    // 格式转换
    handleFormatJson () {
      if (!this.textarea) {
        return this.$message.info('请输入内容')
      }
      if (typeof this.textarea !== 'string') {
        return this.$message.info('请输入字符串类型')
      }
      if (this.isJsonArray(this.textarea)) {
        const arr = JSON.parse(this.textarea)
        const objs = []
        if (arr.length > 0) {
          const list = [...arr]
          list.forEach(function (item, index) {
            objs.push(
              { id: index + 1, label: item }
            )
          })
        }
        this.festivals = Object.assign([], objs)
      } else {
        return this.$message.info('请输入正确格式')
      }
    },
    isJsonArray (str) {
      try {
        if (typeof JSON.parse(str) === 'object') {
          return true
        }
      } catch (e) {
      }
      return false
    }
  }

}
</script>

<style scoped>

</style>
