<template>
  <section class="ufw-section">
    <h3>Ufw 节点内存</h3>

    <el-card class="ufw-card">
      <div slot="header" class="ufw-card-header">
        <div class="header-title">
          <span>节点列表</span>
            <el-popconfirm style="margin-left: 10px;" icon="el-icon-warning" title="你确定要让服务端立即生效么？" @confirm="handleUfwReload">
              <el-button slot="reference" type="danger" size="small">重载</el-button>
            </el-popconfirm>
        </div>
        <template v-for="(item, index) in nodeOption">
          <el-radio v-model="nodeHost" :label="item" :key="index" @input="onNodeInput"></el-radio>
        </template>
      </div>

      <div class="ufw-content">
        <el-tabs v-model="scopeType" @tab-click="onTabsClick">
          <template v-for="(item, index) in scopeOption">
            <el-tab-pane :key="index" :name="item.value">
              <span slot="label" style="margin: 0 10px;"> {{item.label}} </span>
              <template v-for="(item, index) in ufwOption">
                <el-radio v-model="ufwType" :label="item.value" :key="index">
                  <!-- @input="onUfwSearch(item)" -->
                  {{item.label}}
                </el-radio>
              </template>

              <el-button type="primary" size="small"  @click="onUfwSearch()">查看</el-button>
            </el-tab-pane>
          </template>
        </el-tabs>
        <TableComponents
          :tableData="tableData"
          :column="getColumn"
        >
          <!-- <template slot="slotTest" slot-scope="{row, column}" >
          </template> -->
        </TableComponents>
      </div>
    </el-card>
  </section>
</template>

<script>
import api from '@/api/channel'
import TableComponents from './components/Table'
import { column } from './table'
export default {
  name: 'ufwIndex',
  components: {
    TableComponents
  },
  data: function () {
    return {
      radio: 1,
      nodeOption: [],
      nodeHost: '',
      tableData: [],
      scopeType: '1',
      scopeOption: [
        { id: 1, label: '封禁', value: '1' },
        { id: 2, label: '注册', value: '2' },
        { id: 3, label: '充值', value: '3' }
      ],
      ufwType: '1',
      ufwList: {}
    }
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    getColumn () {
      return column
    },
    ufwOption () {
      return [
        { id: 1, label: '黑名单', value: '1', request: api.ufwBlacklists },
        { id: 3, label: '白名单', value: '3', request: api.ufwWhitelists }
      ]
    }
  },
  mounted: function () {
    this.initFetch()
  },
  methods: {
    initFetch () {
      api.getNodeList().then(res => {
        this.nodeOption = res

        if (!this.nodeHost) {
          this.nodeHost = [...res].shift()
        }
      })
    },
    handleUfwReload () {
      api.ufwReload({
        host: this.nodeHost,
        type: +this.scopeType
      }).then(() => {
        this.$message.warning('重载成功 !')

        // 重载成功重新获取数据
        if (this.ufwType) {
          this.onUfwSearch(this.ufwOption.find(item => +this.ufwType === +item.value))
        }
      })
    },
    onUfwSearch (item) {
      let target = item
      if (!target) {
        target = this.ufwOption.find(item => +this.ufwType === +item.value)
      }
      const loading = this.$loading({
        target: document.querySelector('.ufw-section')
      })
      target.request({ host: this.nodeHost, type: +this.scopeType }).then(res => {
        this.tableData = res.data ?? []
      }).finally(() => loading.close())
    },
    onNodeInput () {
      // this.onUfwSearch()
      // this.ufwType = ''
    },
    onTabsClick () {
      // this.onUfwSearch()
      // this.ufwType = ''
    }
  }
}
</script>

<style scoped>
.ufw-section h3 {
  margin-left: 4px;
}
.el-card__header {
  border-bottom-width: 0;
}
.header-title {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 14px;
}
.header-title span {
  margin-right: 10px;
}
::v-deep.ufw-section .el-radio__input {
  line-height: 12px;
}

::v-deep.ufw-section .el-tabs__item.is-active {
  /* transform: scale(1.08); */
  font-weight: 600;
}

.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
</style>
