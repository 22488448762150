<template>
  <section>
    <div class="main-header">
      <div style="display: flex;justify-content: space-between;">
        <el-button type='primary' @click='handleFormAdd'>新增</el-button>
      </div>
    </div>
    <!-- 主体内容-->
    <el-card>
      <el-table :data='tables' style='width: 100%' stripe :header-cell-style='setTableHeader'>
        <el-table-column prop='id' label='ID' width="100"></el-table-column>
        <el-table-column prop='groupKey' label='GroupKey' width="160">
          <template slot-scope='scope'>
            <el-button type='text' @click='handleFormUpdate(scope.$index, scope.row)'>{{scope.row.groupKey}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop='nacosNameSpace' label='NacosNameSpace' width="200"></el-table-column>
        <el-table-column prop='nacosInstanceListUrl' label='NacosInstanceListUrl'></el-table-column>
        <el-table-column prop='note' label='备注' width="120">
          <template slot-scope="scope">
            <el-popover placement="left" trigger="click" :content="scope.row.note">
              <el-button slot="reference" size="mini" v-if="scope.row.note">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label='操作' align='right' width="120">
          <template slot-scope='scope'>
            <el-button type='primary' size='mini' @click='handleFormUpdate(scope.$index, scope.row)' icon='el-icon-edit' circle></el-button>
            <el-button type='info' size='mini' @click='handleFormDelete(scope.row.id, scope.$index)' icon='el-icon-delete' circle></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!--逻辑模态框-->
    <el-dialog title='ufo分组' :visible='dialogVisible' @close='handleCloseDialog' top='15vh'>
      <el-form :model='form' label-position='right' label-width="160px" :rules='rules' ref='form'>
        <el-form-item label='ID' prop='id'>
          <el-input v-model.number='form.id' :disabled='type' style='width: 100%'></el-input>
        </el-form-item>
        <el-form-item label='GroupKey' prop='groupKey'>
          <el-input v-model.trim='form.groupKey'></el-input>
        </el-form-item>
        <el-form-item label='NacosNameSpace' prop='nacosNameSpace'>
          <el-input v-model.trim='form.nacosNameSpace'></el-input>
        </el-form-item>
        <el-form-item label='NacosInstanceListUrl' prop='nacosInstanceListUrl'>
          <el-input v-model.trim='form.nacosInstanceListUrl' type="textarea" :rows="3"></el-input>
        </el-form-item>
        <el-form-item label='备注' prop='note'>
          <el-input v-model.trim='form.note' type="textarea" :rows="3"></el-input>
        </el-form-item>
      </el-form>
      <div slot='footer' class='dialog-footer' style='text-align: center'>
        <el-button type='primary' @click='handleSaveDialog'>提 交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import api from '@/api/channel'

export default {
  name: 'ufoGroup',
  created () {
    this.fetchData()
  },
  computed: {
    id () {
      return this.$store.getters.id
    },
    tables () {
      const search = this.search
      if (search) {
        return this.tableData.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(search) > -1
          })
        })
      }
      return this.tableData
    }
  },
  watch: {
    id (val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.fetchData()
    }
  },
  data () {
    return {
      tableData: [],
      envData: {},
      dialogVisible: false,
      search: '',
      form: {
        id: this.id
      },
      AppOptions: [],
      type: false, // 默认状态为新增  true 为修改 false为新增
      index: 0, // 用于修改时保存位置,
      rules: {
        id: [{ type: 'number', required: true, message: '请输入数字，且不能为空', trigger: 'blur' }],
        groupKey: [{ type: 'string', required: true, message: '请输入ufo分组', trigger: 'blur' }],
        nacosNameSpace: [{ type: 'string', required: true, message: '请输入NacosNameSpace名称', trigger: 'blur' }],
        nacosInstanceListUrl: [{ type: 'string', required: true, message: '请输入NacosInstanceListUrl', trigger: 'blur' }]
      }
    }
  },
  methods: {
    fetchData () {
      api.getUfoGroups().then(res => {
        this.tableData = res
      })
    },
    handleFormAdd () {
      this.type = false
      const id = this.tableData.length ? this.tableData[this.tableData.length - 1].id : 0
      this.form = {
        id: id + 1
      }
      this.dialogVisible = true
    },
    handleFormUpdate (index, rowData) {
      this.type = true
      this.index = index
      this.form = Object.assign({}, rowData)
      this.dialogVisible = true
    },
    // 提交数据 新增 或 修改
    handleSaveDialog () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.form)
          const f = this.type ? api.updateUfoGroup : api.addUfoGroup
          f(params).then(res => {
            if (this.type) {
              this.tableData.splice(this.index, 1, res)
            } else {
              this.tableData.push(res)
            }
            this.$message.success(this.type ? '修改成功' : '添加成功')
            this.dialogVisible = false
          })
        } else {
          return false
        }
      })
    },
    // 删除
    handleFormDelete (id, i) {
      this.$confirm('确定删除吗?', '提示', { type: 'warning' }).then(() => {
        api.deleteUfoGroup(id).then(() => {
          this.tableData.splice(i, 1)
          this.$message.success('删除成功')
        })
      })
    },
    // 关闭弹窗
    handleCloseDialog () {
      this.dialogVisible = false
      this.$refs.form.clearValidate()
    },
    setTableHeader () {
      return 'color: #000'
    }
  }
}
</script>

<style scoped>
e_card {
  margin-bottom: 20px;
}
</style>
