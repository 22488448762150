// ISO国际标准
const currency = [
  { label: '人民币', value: 'CNY' },
  { label: '台币', value: 'TWD' },
  { label: '美元', value: 'USD' }
]

// 广告类型
const adnetType = [
  { label: '无广告', value: 0 },
  { label: '自适应', value: 1 },
  { label: '穿山甲', value: 2 },
  { label: '优量汇', value: 3 }
]

const statusOption = [
  { label: '关闭', value: -1 },
  { label: '准备对接', value: 0 },
  { label: '对接中', value: 1 },
  { label: '接入完成', value: 2 },
  { label: '测试中', value: 3 },
  { label: '测试通过', value: 4 },
  { label: '上线', value: 5 }
]

// 系统
const os = [
  { label: 'IOS', value: 'ios' },
  { label: 'Android', value: 'android' },
  { label: 'H5', value: 'h5' },
  { label: '小程序', value: 'mp' }
]

// 包类型，其中os是未来兼容旧版os
const packageTypes = [
  { value: 1, label: 'Android', os: 'android' },
  { value: 2, label: 'IOS', os: 'ios' },
  { value: 3, label: 'H5', os: 'h5' },
  { value: 4, label: '小程序', os: 'mp' },
  { value: 5, label: '鸿蒙', os: 'harmony' },
  { value: 6, label: 'windows', os: 'windows' }
]

const platformTypes = [
  { label: '官方包', value: 1 },
  { label: '买量包', value: 2 },
  { label: '渠道包', value: 3 },
  { label: 'CPS包', value: 4 }
]

// 切换用户

const shadowTypes = [
  { label: '关闭', value: 0 },
  { label: '开启', value: 1 }
]

const productStatusOption = [
  { label: '上线', value: 1 },
  { label: '准备', value: 0 },
  { label: '关闭', value: -1 }
]

const rateList = [
  { label: '不打折', value: 0 },
  { label: '1%', value: 100 },
  { label: '10%', value: 1000 },
  { label: '50%', value: 5000 }
]

export default {
  currency,
  statusOption,
  os,
  shadowTypes,
  adnetType,
  productStatusOption,
  packageTypes,
  platformTypes,
  rateList
}
