<template>
  <section>
    <div class="main-header">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleAddChannel">新增</el-button>
        </el-form-item>
        <el-form-item>
          <el-select v-model="pfId" @change="pfIdChange" placeholder="平台ID">
            <el-option value="">全部</el-option>
            <el-option v-for="pf in platformData" :label="pf.platformId + '-' + pf.name" :value="pf.platformId" :key="pf.platformId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="chStatus" @change="chStatusChange" placeholder="状态">
            <el-option value="">全部</el-option>
            <el-option v-for="ops in statusOption" :label="ops.value + '-' + ops.label" :value="ops.value" :key="ops.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search" placeholder="输入关键字搜索（页面）"></el-input>
        </el-form-item>
      </el-form>
    </div>
<!--    主体内容    -->
    <el-card>
      <!-- 分页器 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount">
      </el-pagination>
      <!-- 渠道数据 -->
      <el-table :data="tables" style="width: 100%" stripe :header-cell-style="setTableHeader">
        <el-table-column prop="platformId" label="平台ID" :formatter="platFormFormatter"> </el-table-column>
        <el-table-column prop="id" label="渠道ID"  width="100"> </el-table-column>
        <el-table-column prop="name" label="渠道名称">
          <template slot-scope="scope">
<!--            <el-button type="text" @click="handleUpdateChannel(scope.$index, scope.row)">{{scope.row.name}}</el-button>-->
            <el-link type="primary" @click="handleUpdateChannel(scope.$index, scope.row)">{{scope.row.name}}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="accountChannelId" label="账号渠道ID" width="100"> </el-table-column>
        <el-table-column prop="argsGroupId" label="参数组ID" width="100"> </el-table-column>
        <el-table-column prop="packageType" label="包类型" width="90" :formatter="packageTypeFormatter"></el-table-column>
        <el-table-column prop="channelType" label="ChannelType" width="120" :formatter="channelTypeFormatter"> </el-table-column>
<!--        <el-table-column prop="os" label="os(弃)" width="70"> </el-table-column>-->
        <el-table-column prop="date" label="逻辑参数">
          <template slot-scope="scope">
            <el-button type="text" class="el-icon-edit-outline" v-if="!scope.row.edit" @click="handleArgsDialog(scope.row.edit, scope.row.argsGroupId)"></el-button>
            <div v-else>
              <el-button type="text" @click="handleArgsDialog(scope.row.edit, scope.row.argsGroupId)" :disabled="scope.row.id!==scope.row.argsGroupId">{{scope.row.logicId}}-{{scope.row.logicName}}</el-button>
            </div>
<!--            <el-button type="text" @click="handleArgsDialog(scope.row.edit, scope.row.argsGroupId)" v-else>{{scope.row.logicName}}</el-button>-->
          </template>
        </el-table-column>
        <el-table-column prop="customized" label="商品档位" width="100">
          <template slot-scope="scope">
            <el-button round type="text" @click="handleCustomizedDialog(scope.row.edit, scope.row.argsGroupId)" v-show="scope.row.edit" v-if="!scope.row.customized" style="color: #909399">默认</el-button>
            <el-button round type="text" @click="handleCustomizedDialog(scope.row.edit, scope.row.argsGroupId)" v-show="scope.row.edit" v-else >特殊配置</el-button>
          </template>
        </el-table-column>
        <el-table-column label="平台-支付回调" width="120">
          <template slot-scope="scope">
            <el-popover placement="bottom" trigger="click" :content="scope.row.ptCallbackUrl">
              <el-button slot="reference" size="mini" v-if="scope.row.ptCallbackUrl">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="平台-H5登录Url" width="120">
          <template slot-scope="scope">
            <el-popover placement="bottom" trigger="click" :content="scope.row.ptH5LoginUrl">
              <el-button slot="reference" size="mini" v-if="scope.row.packageType === 3 && scope.row.ptH5LoginUrl">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="minAge" label="适龄" width="80"></el-table-column>
        <el-table-column prop="bizId" label="bizId" width="80">
          <template slot-scope="scope">
            <el-popover placement="bottom" trigger="click" :content="scope.row.bizId">
              <el-button slot="reference" size="mini" v-if="scope.row.bizId">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="realAuthOpen" label="实名" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.realAuthOpen" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="juvenileOpen" label="防沉迷" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.juvenileOpen" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="100px" :formatter="autoFormatter"></el-table-column>
        <el-table-column prop="plutoOpen" label="Pluto" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.plutoOpen" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="ufwOpen" label="UFW" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.ufwOpen" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="phoneBindOpen" label="手机号绑定" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.phoneBindOpen" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="regLimitOpen" label="注册限制" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.regLimitOpen" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="rate" label="折扣率" width="80" :formatter="rateListFormatter"></el-table-column>
        <el-table-column prop="notifyUrl" label="CP-NotifyUrl" width="110">
          <template slot-scope="scope">
            <el-popover placement="bottom" trigger="click" :content="scope.row.notifyUrl">
              <el-button slot="reference" size="mini" v-if="scope.row.notifyUrl">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="h5LoginUrl" label="CP-H5Url" width="100">
          <template slot-scope="scope">
            <el-popover placement="bottom" trigger="click" :content="scope.row.h5LoginUrl">
              <el-button slot="reference" size="mini" v-if="scope.row.h5LoginUrl">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="gameUrl" label="CP-微端Url" width="100">
          <template slot-scope="scope">
            <el-popover placement="bottom" trigger="click" :content="scope.row.gameUrl">
              <el-button slot="reference" size="mini" v-if="scope.row.gameUrl">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="right" width="60">
          <template slot-scope="scope">
            <el-button type="info" size="mini" @click="handleDeleteChannel(scope.row.id, scope.$index)" icon="el-icon-delete" circle v-show="!scope.row.edit"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount">
      </el-pagination>
    </el-card>
<!--    渠道模态框    -->
    <channel-dialog :channelData="channelData" :dialogVisible="dialogVisible" @handleCloseDialog="dialogVisible=false" @handleSaveDialog="handleChannelDialogChange"> </channel-dialog>
<!--    参数配置dialog    -->
    <el-dialog title="参数管理" :visible="argsDialog" @close = "argsDialog = false" top="4vh">
      <el-form :model="argsForm" label-position="right" label-width="80px" :rules="argsRules" ref="argsForm">
        <el-form-item label="参数ID" prop="id" >
          <el-input v-model.number="argsForm.id" :disabled="type"></el-input>
        </el-form-item>
        <el-form-item label="逻辑" prop="logicId">
          <el-select v-model="argsForm.logicId" filterable placeholder="请选择" style="width: 100%;" @change="handleChangeLogicArgs(argsForm.logicId)" clearable>
            <el-option v-for="item in logicData" :key="item[0]" :label="item[0] + '-' + item[1].name" :value="item[0]">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch v-model="argsForm.status" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
        </el-form-item>
        <el-form-item label="充值">
          <el-switch v-model="argsForm.payable" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
        </el-form-item>
        <el-form-item label="注册">
          <el-switch v-model="argsForm.registered" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
        </el-form-item>
        <el-form-item label="企微">
          <el-input type="textarea" v-model="argsForm.workWxHtml" active-color="#13ce66" inactive-color="#ff4949" placeholder="关闭注册后，显示的企业微信内容"> </el-input>
        </el-form-item>
        <el-form-item label="参数">
          <el-form size="mini" :model="argsForm" label-position="left">
            <el-form-item v-for="(item, i) in argsForm.args" :key="i" :label="item.label" style="margin-top: 20px">
              <el-input v-model="item.value ">{{item}}</el-input>
              <el-checkbox v-model="item.isKeep" v-if="item.show">是否保留(逻辑模板已无此参数,删除可能会造成一些影响，慎重)</el-checkbox>
            </el-form-item>
          </el-form>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: left">
        <el-button @click="handleFillArgs()" v-if="type">填充参数</el-button>
        <el-button type="primary" @click="handleSaveArgsDialog()">保 存</el-button>
      </div>
    </el-dialog>
<!--    自定义商品dialog    -->
    <el-dialog title="自定义货币档位" :visible="customizedDialog" @close ="customizedDialog=false" label-position="left" top="4vh">
      <el-form :model="argsForm" label-position="right" label-width="80px" :rules="argsRules" ref="customizedForm">
        <el-form-item label="自定义" prop="customized">
          <el-switch v-model="argsForm.customized" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
        </el-form-item>
        <!--        货币类型         -->
        <el-form-item label="货币类型" v-show="argsForm.customized">
          <el-select v-model="argsForm.currency" placeholder="请选择" style="width: 100%" filterable @change="currencyChange">
            <el-option v-for="item in options" :key="item.value" :label="item.value + '-' + item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="档位" v-show="argsForm.customized">
          <el-form size="mini" :model="customizedForm" >
            <el-form-item style="margin-top: 20px">
              <!--表头-->
              <el-form-item style="float: left;margin-bottom: 5px;width:100%">
                <el-row :gutter="20" style="text-align: left;font-weight: bold">
                  <el-col :span="3"><span>商品ID</span></el-col>
                  <el-col :span="3"><span>价格(分)</span></el-col>
                  <el-col :span="5"><span>商品名称</span></el-col>
                  <el-col :span="5"><span>商品描述</span></el-col>
                  <el-col :span="5"><span>渠道商品ID</span></el-col>
                  <el-col :span="3"><span>操作</span></el-col>
                </el-row>
              </el-form-item>
              <!--参数-->
              <el-form-item v-for="(item, i) in customizedForm.products" :key="i" style="float: left;margin-bottom: 5px;width:100%">
                <el-row :gutter="20">
                  <el-col :span="3"><el-input v-model="item.id" placeholder="商品ID" :disabled="item.disabled"> </el-input></el-col>
                  <el-col :span="3"><el-input v-model="item.price" placeholder="价格(分)" :disabled="item.disabled"> </el-input></el-col>
                  <el-col :span="5"><el-input v-model="item.name" placeholder="商品名称"> </el-input></el-col>
                  <el-col :span="5"><el-input v-model="item.desc" placeholder="商品描述"> </el-input></el-col>
                  <el-col :span="5"><el-input v-model="item.cid" placeholder="渠道商品ID"> </el-input></el-col>
                  <el-col :span="3"><el-button type="info" size="mini" circle icon="el-icon-delete" @click="handleDeleteProductsParams(i)" :disabled="item.disabled"> </el-button></el-col>
                </el-row>
              </el-form-item>
              <el-form-item v-if="formatJson">
                <el-input type="textarea" placeholder="请输入内容" v-model="textarea"> </el-input>
                <el-button type="primary" size="mini" @click="handleFormatJson">自动转换</el-button>
              </el-form-item>
              <el-form-item style="float: left">
                <el-button type="primary" size="mini" @click="handleAddProductsParams()">添加</el-button>
                <el-button type="primary" size="mini" @click="handleAddTextarea">粘贴文本</el-button>
              </el-form-item>
            </el-form-item>
          </el-form>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: left">
        <el-button type="primary" @click="handleSaveArgsDialog">保 存</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import ChannelDialog from './ChannelDialog'
import api from '@/api/channel'
import options from '@/common/options'
import f from '@/filter'

export default {
  name: 'index',
  components: { ChannelDialog },
  created () {
    this.$store.dispatch('GetChannelTypeList').then((res) => {
      this.channelTypeList = res
    })
    this.fetchData(this.pagesize, this.currentPage)
  },
  computed: {
    appId () {
      return this.$store.getters.appId
    },
    appInfo () {
      return this.$store.getters.appInfo
    },
    tables () {
      const search = this.search
      if (search) {
        return this.tableData.filter(data => {
          return Object.keys(data).some(key => {
            return String(data[key]).toLowerCase().indexOf(search) > -1
          })
        })
      }
      return this.tableData
    }
  },
  watch: {
    appId (val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.fetchData(this.pagesize, this.currentPage)
    }
  },
  data () {
    return {
      pagesize: 20,
      currentPage: 1,
      totalCount: 0,
      tableData: [],
      channelData: {},
      argsData: new Map(),
      chStatus: '',
      pfId: '',
      search: '',
      argsForm: {
        currency: 'CNY',
        products: []
      },
      type: false, // 默认状态为新增  true 为修改 false为新增
      logicData: new Map(),
      platformData: [],
      dialogVisible: false,
      argsDialog: false,
      customizedDialog: false,
      customizedForm: {
        products: [{ id: '', name: '', price: 0, desc: '', cid: '' }]
      },
      options: options.currency,
      statusOption: options.statusOption,
      argsRules: {
        id: { type: 'number', required: true, message: '请输入id，且不能为空', trigger: 'change' },
        logicId: { type: 'number', required: true, message: '请输入logicId，且不能为空', trigger: 'change' },
        status: { type: 'boolean', required: true, message: '请选择状态', trigger: 'change' },
        customized: { type: 'boolean', required: true, message: '请选择状态', trigger: 'change' }
      },
      textarea: '',
      formatJson: false,
      // gatewayUrl: '',
      channelTypeList: [],
      areaMap: new Map()
    }
  },
  methods: {
    fetchData (n1, n2) {
      const params = {
        size: n1,
        page: n2,
        pfId: this.pfId,
        chStatus: this.chStatus,
        appId: this.appId
      }
      api.getAreas().then(res => {
        this.areaMap = this.mapData(res)
      })
      api.getPlatformData(this.appId).then(res => {
        this.platformData = res
      })
      api.getLogicData().then(res => {
        this.logicData = this.mapData(res)
      })
      api.getArgsData().then(res => {
        this.argsData = this.mapData(res)
        api.getChannelDataByPage(params).then(res => {
          const list = res.data
          this.totalCount = res.total
          for (const item of list) {
            // true 是修改  false 是新增
            item.edit = this.argsData.has(item.argsGroupId)
            if (item.edit) {
              item.customized = this.argsData.get(item.argsGroupId).customized
              const logicId = this.argsData.get(item.argsGroupId).logicId
              item.logicId = logicId
              item.logicName = this.logicData.get(logicId).name
            }
            item.ptH5LoginUrl = this.getPtH5LoginUrl(item)
            item.ptCallbackUrl = this.getPtCallback(item)
          }
          this.tableData = list
        })
      })
    },
    pfIdChange (val) {
      this.pfId = val
      this.currentPage = 1
      this.fetchData(this.pagesize, this.currentPage)
    },
    chStatusChange (val) {
      this.chStatus = val
      this.currentPage = 1
      this.fetchData(this.pagesize, this.currentPage)
    },
    handleSizeChange (val) {
      this.pagesize = val
      this.currentPage = 1
      this.fetchData(val, this.currentPage)
    },
    // 显示第几页
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData(this.pagesize, val)
    },
    // 新增渠道
    handleAddChannel () {
      const id = this.tableData.length ? this.tableData[this.tableData.length - 1].id : 0
      this.channelData = { id: id + 1, accountChannelId: id + 1, argsGroupId: id + 1, os: '', status: 0, areaId: 0 }
      this.dialogVisible = true
    },
    // 修改渠道
    handleUpdateChannel (index, rowData) {
      this.channelData = Object.assign({}, rowData)
      this.dialogVisible = true
    },
    // 渠道 删除
    handleDeleteChannel (id, i) {
      this.$confirm('确定删除吗?', '提示', { type: 'warning' }).then(() => {
        api.deleteChannel(id).then(() => {
          this.tableData.splice(i, 1)
          this.$message.success('删除成功')
        })
      })
    },
    // 关闭渠道模态框
    handleChannelDialogChange (value) {
      this.dialogVisible = value
      this.fetchData(this.pagesize, this.currentPage)
    },
    // 打开参数dialog
    handleArgsDialog (edit, argsGroupId) {
      this.argsDialog = true
      this.type = !!edit
      // edit true 修改 false 新增
      if (!edit) {
        this.argsForm = {
          id: argsGroupId,
          currency: 'CNY',
          status: true,
          registered: true,
          workWxHtml: '',
          payable: true,
          args: [],
          products: null
        }
      } else {
        this.fetchArgsData(argsGroupId)
      }
    },
    // 获取参数数据
    fetchArgsData (argsGroupId) {
      api.getArgs(argsGroupId).then(res => {
        const data = Object.assign({}, res)
        data.args = JSON.parse(data.args)
        for (const item of this.logicData) {
          if (item[0] === data.logicId) {
            data.args = this.mapLogic(JSON.parse(item[1].args), data.args)
          }
        }
        // 以下为product 的操作
        const products = new Map() // 最终结果
        // 来自游戏的档位参数
        const appProductData = JSON.parse(this.appInfo.products)
        const appMap = new Map()
        if (appProductData) {
          for (const item of appProductData) {
            appMap.set(item.id, item)
          }
        }
        // 来自服务器的自定义档位参数
        const customizedProductData = data.products ? JSON.parse(data.products) : []
        const customizedSet = new Set()
        if (customizedProductData.length) {
          for (const item of customizedProductData) {
            // 来自服务器数据全部可修改
            item.disabled = false
            customizedSet.add(item.id)
            products.set(item.id, item)
            if (appMap.has(item.id)) {
              item.disabled = true
            }
          }
        }
        // 来自游戏的数据与游戏的数据做比对，将来自游戏的档位状态改为不可修改
        for (const key of appMap.keys()) {
          if (!customizedSet.has(key)) {
            const data = appMap.get(key)
            data.disabled = true
            data.cid = ''
            products.set(key, data)
          }
        }
        const productArr = []
        for (const item of products) {
          productArr.push(item[1])
        }
        // 对比完成之后的货币档位参数存放在其他的地方
        this.customizedForm.products = productArr
        this.argsForm = data
      })
    },
    // 当参数模板的逻辑ID改变时
    handleChangeLogicArgs (id) {
      let data = []
      for (const item of this.logicData) {
        if (item[0] === id) {
          data = JSON.parse(item[1].args)
          this.argsForm.args = data
        }
      }
    },
    // 填充参数
    handleFillArgs () {
      const args = this.logicData.get(this.argsForm.logicId).args
      const logicTemplate = args ? JSON.parse(args) : null
      const logicTemplateMap = new Map()
      for (const item of logicTemplate) {
        logicTemplateMap.set(item.key, item)
      }
      if (logicTemplate) {
        for (const item of this.argsForm.args) {
          if (!item.value) {
            item.value = logicTemplateMap.get(item.key).value
          }
        }
      }
    },
    // 保存或修改参数
    handleSaveArgsDialog () {
      const params = Object.assign({}, this.argsForm)
      // 先处理product 去除里面的空值以及每个属性的disable
      if (this.customizedForm.products.length > 0) {
        let len = this.customizedForm.products.length
        const arr = this.customizedForm.products
        while (len--) {
          delete arr[len].disabled
          if (arr[len].id === '') {
            arr.splice(len, 1)
          }
        }
      }
      if (params.customized) {
        params.products = this.customizedForm.products.length > 0 ? JSON.stringify(this.customizedForm.products) : null
      }
      const args = {}
      // item.hasOwnProperty('isKeep')
      for (const item of params.args) {
        args[item.key] = item.value
        if (Object.prototype.hasOwnProperty.call(item, 'isKeep') && !item.isKeep) {
          delete args[item.key]
        }
      }
      params.args = JSON.stringify(args)
      const f = this.type ? api.changeArgs : api.addArgs
      f(params).then(() => {
        this.$message.success(this.type ? '修改成功' : '添加成功')
        this.fetchData(this.pagesize, this.currentPage)
        // this.$store.dispatch('GetApp').then(() => {})
        this.argsDialog = false
        this.customizedDialog = false
        this.$refs.argsForm.clearValidate()
        this.$refs.customizedForm.clearValidate()
      })
    },
    // 打开自定义货币dialog
    handleCustomizedDialog (edit, argsGroupId) {
      this.type = !edit
      this.fetchArgsData(argsGroupId)
      this.customizedDialog = true
    },
    // 添加货币档位参数
    handleAddProductsParams () {
      let params = {}
      params = { id: '', name: '', price: 0, desc: '', cid: '', disabled: false }
      if (!this.customizedForm.products) {
        this.customizedForm.products = []
      }
      this.customizedForm.products.push(params)
    },
    // 打开文本输入
    handleAddTextarea () {
      this.formatJson = !this.formatJson
    },
    // 删除货币档位参数
    handleDeleteProductsParams (i) {
      this.customizedForm.products.splice(i, 1)
    },
    // 改变货币种类时
    currencyChange () {
      // 发送请求，并修改参数模板
      const params = { appId: this.appId, currency: this.argsForm.currency }
      // 请求货币接口
      api.getCurrencyData(params).then(res => {
        const data = Object.assign({}, res)
        if (data.products) {
          this.customizedForm.products = JSON.parse(data.products)
        }
      })
    },
    autoFormatter (row, column, cellValue) {
      return f.autoFormat(column.property, cellValue)
    },
    getArea (areaId) {
      const id = areaId || this.appInfo.areaId
      return this.areaMap.get(id) || {}
    },
    // 生成ptCallback
    getPtCallback (row) {
      const logicId = row.logicId
      if (!logicId) {
        return null
      }
      const area = this.getArea(row.areaId) || {}
      return area.callbackUrl + `/callback/c${logicId}/${row.id}`
    },
    // 生成ptH5loginURL
    getPtH5LoginUrl (row) {
      const logicId = row.logicId
      if (!logicId) {
        return null
      }
      const area = this.getArea(row.areaId) || {}
      return area.gatewayUrl + `/h5/login/c${logicId}/${row.id}`
    },
    platFormFormatter (row) {
      for (const item of this.platformData) {
        if (item.platformId === row.platformId) {
          return item.platformId + '-' + item.name
        }
      }
    },
    packageTypeFormatter (row) {
      const packageType = row.packageType
      const item = options.packageTypes.find(item => item.value === packageType)
      if (item) {
        return item.value + '-' + item.label
      }
      return packageType
    },
    rateListFormatter (row) {
      const rate = row.rate
      const item = options.rateList.find(item => item.value === rate)
      if (item) {
        return item.label
      }
      return rate
    },
    channelTypeFormatter (row) {
      const channelType = row.channelType
      const item = this.channelTypeList.find(item => item.id === channelType)
      if (item) {
        return item.id + '-' + item.name
      }
      return channelType
    },
    mapData (value) {
      const data = new Map()
      for (const item of value) {
        data.set(item.id, item)
      }
      return data
    },
    mapLogic (arr, obj) {
      //  obj是服务器拿下来的 arr是模板
      const arrMap = new Map()
      for (const item of arr) {
        arrMap.set(item.key, item)
        item.value = obj[item.key]
      }
      for (const item in obj) {
        if (!arrMap.has(item)) {
          arr.push({ key: item, value: obj[item], label: item, isKeep: true, show: true })
        }
      }
      return arr
    },
    setTableHeader () {
      return 'color: #000'
    },
    // 格式转换
    handleFormatJson () {
      if (!this.textarea) {
        return this.$message.info('请输入内容')
      }
      if (typeof this.textarea !== 'string') {
        return this.$message.info('请输入字符串类型')
      }
      if (this.isJsonString(this.textarea)) {
        this.products = JSON.parse(this.textarea)
      } else {
        return this.$message.info('请输入正确格式')
      }
    },
    isJsonString (str) {
      try {
        if (typeof JSON.parse(str) === 'object') {
          return true
        }
      } catch (e) {
      }
      return false
    }
  }
}
</script>

<style scoped>
  .el-form-item {
    margin-bottom: 20px;
  }
</style>
