<template>
  <el-dialog title='产品' :visible='dialogVisible' @close='handleCloseProductDialog' label-position='left' top='4vh'>
    <el-form :model='form' :rules='rules' ref='form' label-position='right' label-width="100px">
      <el-form-item label='ID' prop='id'>
        <el-input v-model.number='form.id' :disabled='type' style='width: 100%' ></el-input>
      </el-form-item>
      <el-form-item label='名称' prop='name'>
        <el-input v-model.trim='form.name' placeholder='请输入名称'></el-input>
      </el-form-item>
      <el-form-item label='AppKey' prop='appKey'>
        <el-input v-model.trim='form.appKey' placeholder='请输入appKey'></el-input>
      </el-form-item>
      <el-form-item label='AppSecret' prop='appSecret'>
        <el-input v-model.trim='form.appSecret' placeholder='请输入appSecret'></el-input>
      </el-form-item>
      <el-form-item label='状态'>
        <el-select v-model='form.status' placeholder='请选择产品状态' style='width: 100%'>
          <el-option v-for='item in productStatusOption' :key='item.value' :label='item.value + "-" + item.label' :value='item.value'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="CP-NotifyUrl" prop="notifyUrl">
        <el-input v-model.trim="form.notifyUrl" placeholder="请输入 CP NotifyUrl"></el-input>
      </el-form-item>
      <el-form-item label="CP-H5Url" prop="h5LoginUrl">
        <el-input v-model.trim="form.h5LoginUrl" placeholder="请输入 CP H5LoginUrl"></el-input>
      </el-form-item>
      <el-form-item label="CP-微端Url" prop="gameUrl">
        <el-input v-model.trim="form.gameUrl" placeholder="请输入 CP 微端GameUrl"></el-input>
      </el-form-item>
      <el-form-item label="网关域名" prop="areaId">
        <el-select v-model="form.areaId" filterable placeholder="请选择网关域名" style="width: 100%;" @change="handleChangeAreaArgs(form.areaId)" clearable>
          <el-option value="">请选择</el-option>
          <el-option v-for="area in areaData" :key="area.id" :label="area.id + '-' + area.name" :value="area.id"></el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label='GatewayUrl' prop="gatewayUrl">-->
<!--        <el-input v-model.trim='form.gatewayUrl' placeholder='请输入GatewayUrl'></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label='AppStoreId' prop='appStoreId'>
        <el-input v-model='form.appStoreId' placeholder='请输入AppStoreId'></el-input>
      </el-form-item>
      <el-form-item label='货币类型' prop='options'>
        <el-select v-model='form.currency' placeholder='请选择货币类型' style='width: 100%'>
          <el-option v-for='item in currencyOptions' :key='item.value' :label='item.value + "-" + item.label' :value='item.value'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Pluto" prop="plutoOpen">
        <el-switch v-model="form.plutoOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="UFW" prop="ufwOpen">
        <el-switch v-model="form.ufwOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="实名认证" prop="realAuthOpen">
        <el-switch v-model="form.realAuthOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="手机号绑定" prop="phoneBindOpen">
        <el-switch v-model="form.phoneBindOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="预约归因" prop="reserveAttributeOpen">
        <el-switch v-model="form.reserveAttributeOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="防沉迷" prop="juvenileOpen">
        <el-switch v-model="form.juvenileOpen" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      </el-form-item>
      <el-form-item label="适龄" prop="minAge">
        <el-input-number v-model="form.minAge" :min="0" :max="100"></el-input-number>
      </el-form-item>
      <el-form-item label="备案识别码" prop="bizId">
        <el-input v-model="form.bizId" placeholder='请输入备案识别码 bizId'></el-input>
      </el-form-item>
      <el-form-item label="ufo分组" prop="options">
        <el-select v-model="form.ufoGroup" placeholder="请选择ufo分组" style="width: 100%">
          <el-option value="">不分组</el-option>
          <el-option v-for="item in ufoGroupOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot='footer' class='dialog-footer' style='text-align: left'>
      <el-button type='primary' @click='handleSaveDialog' >保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api/channel'
import options from '../../common/options'

export default {
  name: 'ProductDialog',
  props: {
    dialogVisible: Boolean,
    productData: Object
  },
  created () {
    // console.log(this.productData)
  },
  watch: {
    productData: function (val) {
      this.fetchData()
      this.type = !!val.name // true 为改变 false 为新增
      this.form = Object.assign({}, val)
    }
  },
  data () {
    return {
      productStatusOption: options.productStatusOption,
      currencyOptions: options.currency,
      form: {
        status: 1,
        currency: 'CNY',
        gatewayUrl: 'https://sgw.begindcc.com',
        realAuthOpen: false,
        phoneBindOpen: false,
        reserveAttributeOpen: false,
        juvenileOpen: false,
        plutoOpen: false,
        ufwOpen: false,
        minAge: 0,
        bizId: '',
        areaId: '',
        ufoGroup: ''
      },
      areaData: [],
      type: '',
      rules: {
        id: [{ type: 'number', required: true, message: '请输入数字，且不能为空', trigger: 'change' }],
        appKey: [{ type: 'string', required: true, message: 'appKey不能为空', trigger: 'blur' }],
        appSecret: [{ type: 'string', required: true, message: 'appSecret不能为空', trigger: 'blur' }],
        appStoreId: [{ type: 'string', required: false, message: '请输入appStoreId', trigger: 'blur' }],
        currency: [{ type: 'string', required: false, message: '请选择货币类型', trigger: 'blur' }],
        areaId: [{ type: 'number', required: true, min: 1, message: '请选择网关域名', trigger: 'blur' }],
        name: [{ type: 'string', required: true, message: '请输入产品名称', trigger: 'blur' }],
        notifyUrl: [{ type: 'url', required: true, message: 'NotifyUrl格式不正确', trigger: 'blur' }],
        h5LoginUrl: [{ type: 'url', required: false, message: 'h5LoginUrl格式不正确', trigger: 'blur' }],
        gameUrl: [{ type: 'url', required: false, message: 'gameUrl格式不正确', trigger: 'blur' }]
      },
      ufoGroupOptions: []
    }
  },
  methods: {
    fetchData () {
      this.areaData = []
      api.getAreas().then(res => {
        this.areaData = res
      })
      this.ufoGroupOptions = []
      api.getUfoGroups().then(res => {
        const arr = []
        for (const d of res) {
          arr.push({ value: d.groupKey, label: d.groupKey })
        }
        this.ufoGroupOptions = arr
      })
    },
    // 关闭模态框
    handleCloseProductDialog () {
      this.$refs.form.clearValidate()
      this.$emit('handleCloseProductDialog', false)
    },
    // 当参数模板的环境ID改变时
    handleChangeAreaArgs (areaId) {
      for (const item of this.areaData) {
        if (item.id === areaId) {
          this.$store.state.app.gatewayUrl = item.callbackUrl
        }
      }
    },
    // 发送数据判断是 添加 还是 修改  成功之后关闭
    handleSaveDialog () {
      // 先进行表单验证
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.form)
          // products已经单独拆分出dialog，这边不需要关心
          params.products = null
          const f = this.type ? api.changeProduct : api.addProduct
          f(params).then(res => {
            this.$message.success(this.type ? '修改成功' : '添加成功')
            this.$emit('handleSaveProductDialog', res)
            this.$store.dispatch('ChangeAppInfo', res).then(() => {})
          })
        } else {
          return false
        }
      })
    },
    handleClose (done) {
      done()
    }
  }
}
</script>

<style scoped>

</style>
