import http from '@/api/http'

export default {
  get (path) {
    return http.get(path)
  },
  post (path, data) {
    return http.post(path, data)
  },
  put (path, data) {
    return http.put(path, data)
  },
  patch (path, data) {
    return http.patch(path, data)
  },
  delete (path) {
    return http.delete(path)
  }
}
